<template>
  <v-col class="mb-5" cols="auto" lg="12" md="12" sm="12">
    <v-card>
      <v-data-table
        :footer-props="{
          pageText: `{0}-{1} ${$t('tables.of')} {2}`,
          itemsPerPageText: $t('tables.vehiclesMaintenanceByPage'),
          itemsPerPageOptions: [5, 10, 20, 50, 100],
        }"
        :headers="vehicleMaintenanceHeaders"
        :no-data-text="$t('tables.noDataText')"
        :no-results-text="$t('tables.noDataText')"
        :items="vehicleMaintenances"
        :search="searchVehicleMaintenanceString"
        class="elevation-1 cursor-pointer"
        @click:row="editVehicleMaintenanceItem"
        multi-sort>
        <template v-slot:item.note="{ item }">
          {{ item.note?.length > 100 ? item.note.slice(0, 100) + '...' : item.note }}
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <template v-if="!vehicleId">
              <v-toolbar-title>
                {{ $t('tables.vehiclesMaintenance') }}
              </v-toolbar-title>

              <v-divider class="mx-4" inset vertical></v-divider>
            </template>

            <v-text-field
              v-model="searchVehicleMaintenanceString"
              append-icon="mdi-magnify"
              hide-details
              :label="$t('tables.search')"
              single-line></v-text-field>

            <v-spacer />

            <v-dialog v-model="vehicleMaintenanceDialog" max-width="700px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                  {{ $t('buttons.add') }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="info title white--text font-weight-bold">
                  {{ $t(vehicleMaintenanceFormTitle) }}
                  <v-spacer />
                  <v-icon class="mdi mdi-close" style="color: white" @click="closeVehicleMaintenanceEdit"></v-icon>
                </v-card-title>
                <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                  <v-form lazy-validation @submit.prevent="handleSubmit(saveVehicleMaintenance)">
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" hidden md="6" sm="12">
                            <v-text-field
                              v-model="editedVehicleMaintenanceItem.id"
                              :label="$t('vehicleMaintenancePage.id')"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12" sm="12">
                            <validation-provider rules="required" v-slot="{ errors }" name="vehicle">
                              <v-autocomplete
                                v-model="editedVehicleMaintenanceItem.vehicle_id"
                                :items="vehicles"
                                clearable
                                item-text="name"
                                item-value="id"
                                :label="$t('vehicleMaintenancePage.vehicle')"
                                :error-messages="errors"
                                :no-data-text="$t('select.noDataAvailable')"
                                @focus="handleSelectFocus($event)"></v-autocomplete>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="12" sm="12">
                            <validation-provider rules="required|max:1000" v-slot="{ errors }" name="description">
                              <v-textarea
                                v-model="editedVehicleMaintenanceItem.note"
                                clearable
                                counter="1000"
                                :label="$t('vehicleMaintenancePage.description')"
                                :error-messages="errors"
                                rows="2"
                                auto-grow></v-textarea>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" lg="6" md="6" sm="12">
                            <v-menu
                              ref="vehicleMaintenanceStartDate"
                              v-model="vehicleMaintenanceStartDate"
                              :close-on-content-click="false"
                              :return-value.sync="editedVehicleMaintenanceItem.date_from"
                              min-width="auto"
                              offset-y
                              transition="scale-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <validation-provider rules="required" v-slot="{ errors }" name="time_from">
                                  <v-text-field
                                    v-model="editedVehicleMaintenanceItem.date_from"
                                    clearable
                                    :label="$t('vehicleMaintenancePage.dateFrom')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </template>
                              <DatePicker
                                v-model="editedVehicleMaintenanceItem.date_from"
                                :model-config="modelConfig"
                                mode="date"
                                :first-day-of-week="firstDay"
                                @input="$refs.vehicleMaintenanceStartDate.save(editedVehicleMaintenanceItem.date_from)">
                              </DatePicker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" lg="6" md="6" sm="12">
                            <v-menu
                              ref="vehicleMaintenanceEndDate"
                              v-model="vehicleMaintenanceEndDate"
                              :close-on-content-click="false"
                              :return-value.sync="editedVehicleMaintenanceItem.date_to"
                              min-width="auto"
                              offset-y
                              transition="scale-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <validation-provider
                                  :rules="'required|date_compare:' + editedVehicleMaintenanceItem.date_from"
                                  v-slot="{ errors }"
                                  name="time_to">
                                  <v-text-field
                                    v-model="editedVehicleMaintenanceItem.date_to"
                                    clearable
                                    :label="$t('vehicleMaintenancePage.dateTo')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </template>
                              <DatePicker
                                v-model="editedVehicleMaintenanceItem.date_to"
                                :model-config="modelConfig"
                                mode="date"
                                :first-day-of-week="firstDay"
                                @input="$refs.vehicleMaintenanceEndDate.save(editedVehicleMaintenanceItem.date_to)">
                              </DatePicker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <add-files
                              :editedItem="editedVehicleMaintenanceItem"
                              :attachments="editedVehicleMaintenanceItem.files"
                              :filesToSend="filesToSend"
                              deleteRoute="vehicleMaintenances/deleteVehicleMaintenanceAttachment"
                              @addNewFile="addNewFile"
                              @deleteNewFile="deleteNewFile"
                              @deleteOldFile="deleteOldFile">
                            </add-files>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                    </v-card-actions>
                  </v-form>
                </validation-observer>
              </v-card>
            </v-dialog>

            <v-dialog v-model="vehicleMaintenanceDialogDelete" max-width="650">
              <v-card>
                <v-card-title class="info title white--text font-weight-bold">
                  {{ editedVehicleMaintenanceItem.vehicle?.name || 'N/A' }}
                  <v-spacer />
                  <v-icon class="mdi mdi-close" style="color: white" @click="closeVehicleMaintenanceDelete"></v-icon>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <p>
                      {{ $t('vehicleMaintenancePage.deleteMessage') }}
                    </p>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    :disabled="!enableVehicleExpenseSave"
                    class="primary"
                    text
                    @click="deleteVehicleMaintenanceConfirm">
                    {{ $t('buttons.yes') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-tooltip left>
                <template v-slot:activator="{ on: tooltipOn }">
                  <v-btn text v-on="{ ...on, ...tooltipOn }">
                    <v-icon> mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t('buttons.actions') }}
                </span>
              </v-tooltip>
            </template>
            <v-list>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item @click="editVehicleMaintenanceItem(item)">
                    <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                    <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                      {{ $t('buttons.edit') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <span>{{ $t('tooltips.edit') }}</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item @click="deleteVehicleMaintenanceItem(item)">
                    <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                    <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                      {{ $t('buttons.delete') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <span>{{ $t('tooltips.delete') }}</span>
              </v-tooltip>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </v-col>
</template>

<script>
import { DatePicker } from 'v-calendar/src/components';
import i18n from '@/i18n/i18n';
import { vehicleMaintenanceHeaders } from '@/mixins/data-table-headers';
import { defaultVehicleMaintenance } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import AddFiles from '@/components/AddFiles.vue';
import store from '@/store';
import { formatDateToDDMM } from '@/utils/formatDate';

export default {
  name: 'VehicleMaintenancesPage',
  components: { AddFiles, DatePicker, ButtonSubmit },
  props: ['fromDate', 'toDate', 'vehicleFilter', 'vehicleId'],
  data: () => ({
    searchVehicleMaintenanceString: '',
    vehicleMaintenanceDialog: false,
    vehicleMaintenanceDialogDelete: false,
    vehicleMaintenances: [],
    vehicleMaintenanceFormTitle: 'vehicleMaintenancePage.newItem',
    vehicleExpenseFormValid: true,
    vehicleMaintenanceFormValid: true,
    editedVehicleExpenseItem: {},
    editedVehicleMaintenanceItem: {},
    vehicleExpenseDate: null,
    vehicleMaintenanceStartDate: null,
    vehicleMaintenanceEndDate: null,
    enableVehicleExpenseSave: true,
    enableVehicleMaintenanceSave: true,
    modelConfig: {
      type: 'string',
      mask:
        store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
          ? 'MM-DD-YYYY'
          : 'DD-MM-YYYY',
    },
    loading: false,
    filesToSend: [],
  }),
  created() {
    this.editedVehicleMaintenanceItem = Object.assign(
      {},
      {
        ...defaultVehicleMaintenance,
        date_from: formatDateToDDMM(this.getCurrentDate()),
        date_to: formatDateToDDMM(this.getCurrentDate()),
        vehicle_id: this.vehicleId,
      }
    );
    this.loadAllVehicleMaintenances();
  },
  computed: {
    vehicleMaintenanceHeaders() {
      return vehicleMaintenanceHeaders(i18n);
    },
    vehicles() {
      return this.$store.getters['vehicles/getVehicles'].filter((item) => {
        return parseInt(item.active) === 1 || item?.id === this.editedVehicleMaintenanceItem?.vehicle_id;
      });
    },
  },
  methods: {
    async loadAllVehicleMaintenances() {
      if (this.vehicleId) {
        let data = {
          id: this.vehicleId,
        };
        await this.$store.dispatch('vehicleMaintenances/getAllVehicleData', data).then((res) => {
          this.vehicleMaintenances = res.data.vehicle_maintenance;
        });
      } else {
        let data = {
          vehicle_id: this.vehicleFilter,
          from_date: this.fromDate ? formatDateToDDMM(this.fromDate) : null,
          to_date: this.toDate ? formatDateToDDMM(this.toDate) : null,
        };
        await this.$store.dispatch('vehicleMaintenances/getAllVehicleMaintenances', data).then((res) => {
          this.vehicleMaintenances = res.data.map((item) => {
            return {
              ...item,
              date_from: formatDateToDDMM(item.date_from),
              date_to: formatDateToDDMM(item.date_to),
            };
          });
        });
      }
    },

    editVehicleMaintenanceItem(item) {
      this.editedVehicleMaintenanceItem = this.vehicleMaintenances.indexOf(item);
      this.editedVehicleMaintenanceItem = Object.assign({}, item);
      this.editedVehicleMaintenanceItem.vehicle_id = parseInt(item.vehicle?.id);
      this.vehicleMaintenanceFormTitle = item.vehicle?.name;
      this.vehicleMaintenanceDialog = true;
    },

    deleteVehicleMaintenanceItem(item) {
      this.editedVehicleMaintenanceItem = this.vehicleMaintenances.indexOf(item);
      this.editedVehicleMaintenanceItem = Object.assign({}, item);
      this.vehicleMaintenanceDialogDelete = true;
    },

    async saveVehicleMaintenance() {
      this.enableVehicleMaintenanceSave = false;
      this.loading = true;
      let path = 'saveVehicleMaintenance';
      let formData = this.createFormData();

      if (this.editedVehicleExpenseItem.id) {
        path = 'updateVehicleMaintenance';
      }

      await this.$store
        .dispatch('vehicleMaintenances/' + path, formData)
        .then(() => {
          this.loadAllVehicleMaintenances();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closeVehicleMaintenanceEdit();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableVehicleExpenseSave = true;
          this.loading = false;
          this.vehicleMaintenanceFormTitle = 'vehicleMaintenancePage.newItem';
        });
    },

    createFormData() {
      let formData = new FormData();

      formData.append('id', this.editedVehicleMaintenanceItem.id ? this.editedVehicleMaintenanceItem.id : '');
      formData.append(
        'vehicle_id',
        this.editedVehicleMaintenanceItem.vehicle_id ? this.editedVehicleMaintenanceItem.vehicle_id : ''
      );
      formData.append('note', this.editedVehicleMaintenanceItem.note ? this.editedVehicleMaintenanceItem.note : '');
      formData.append(
        'date_from',
        this.editedVehicleMaintenanceItem.date_from ? formatDateToDDMM(this.editedVehicleMaintenanceItem.date_from) : ''
      );
      formData.append(
        'date_to',
        this.editedVehicleMaintenanceItem.date_to ? formatDateToDDMM(this.editedVehicleMaintenanceItem.date_to) : ''
      );
      this.filesToSend.map((item) => {
        formData.append('files[]', item);
      });

      return formData;
    },

    async deleteVehicleMaintenanceConfirm() {
      await this.$store
        .dispatch('vehicleMaintenances/deleteVehicleMaintenance', this.editedVehicleMaintenanceItem)
        .then((res) => {
          if (res.success) {
            this.loadAllVehicleMaintenances();
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.closeVehicleMaintenanceDelete();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closeVehicleMaintenanceDelete();
        });
    },

    closeVehicleMaintenanceEdit() {
      this.vehicleMaintenanceDialog = false;
      this.$nextTick(() => {
        this.editedVehicleMaintenanceItem = Object.assign(
          {},
          {
            ...defaultVehicleMaintenance,
            date_from: formatDateToDDMM(this.getCurrentDate()),
            date_to: formatDateToDDMM(this.getCurrentDate()),
            vehicle_id: this.vehicleId,
          }
        );
      });
      this.$refs.form.reset();
      this.filesToSend = [];
      this.vehicleMaintenanceFormTitle = 'vehicleMaintenancePage.newItem';
    },

    closeVehicleMaintenanceDelete() {
      this.vehicleMaintenanceDialogDelete = false;
      this.$nextTick(() => {
        this.editedVehicleMaintenanceItem = Object.assign(
          {},
          {
            ...defaultVehicleMaintenance,
            date_from: formatDateToDDMM(this.getCurrentDate()),
            date_to: formatDateToDDMM(this.getCurrentDate()),
            vehicle_id: this.vehicleId,
          }
        );
      });
    },

    getCurrentDate() {
      const date = new Date();

      return this.getFormattedDate(date);
    },

    getFormattedDate(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },

    handleSelectFocus(event) {
      setTimeout(() => {
        event.target.click();
      }, 250);
    },

    addFiles() {
      this.$refs.addFilesRef.$refs.input.click();
    },
    selectFiles() {
      this.editedDrivingItem.files.map((item) => {
        let extension = item.name.substring(item.name.lastIndexOf('.') + 1);
        if (!['png', 'docx', 'xlsx', 'xls', 'pdf', 'jpg', 'jpeg'].includes(extension)) {
          this.$store.dispatch('showSnackbar', {
            text: i18n.t('customValidations.fileType', { extension }),
            color: 'red',
          });
        } else if (item.size > 5 * 1024 * 1024) {
          this.$store.dispatch('showSnackbar', {
            text: i18n.t('customValidations.fileSize', { size: '5MB' }),
            color: 'red',
          });
        } else {
          this.$emit('addNewFile', item);
        }
      });
    },
    addNewFile(item) {
      this.filesToSend = [...this.filesToSend, item];
    },
    deleteOldFile(index) {
      this.editedVehicleMaintenanceItem.files.splice(index, 1);
    },
    deleteNewFile(index) {
      this.filesToSend.splice(index, 1);
    },
  },

  watch: {
    vehicleMaintenanceDialog(val) {
      if (!val) {
        this.closeVehicleMaintenanceEdit();
      }
    },
    fromDate: {
      handler() {
        this.loadAllVehicleMaintenances();
      },
    },
    toDate: {
      handler() {
        this.loadAllVehicleMaintenances();
      },
    },
    vehicleFilter: {
      handler() {
        this.loadAllVehicleMaintenances();
      },
    },
    vehicleId: {
      handler() {
        this.editedVehicleMaintenanceItem.vehicle_id = this.vehicleId;
        this.loadAllVehicleMaintenances();
      },
    },
  },
};
</script>
